export function getRegistrationNextStep () {
  const storedQuery = sessionStorage.getItem('registrationFlowNextStep')
  if (!storedQuery) {
    return null
  }

  try {
    return JSON.parse(storedQuery)
  } catch (e) {
    return null
  }
}

export function removeRegistrationNextStep () {
  sessionStorage.removeItem('registrationFlowNextStep')
}
