<template>
  <div
    class="col text-center"
    style="margin-top: 30%"
  >
    <div class="row justify-center">
      <v-progress-circular
        :rotate="360"
        indeterminate
        :size="200"
        :width="20"
        color="primary"
      />
    </div>
    <h1 class="row justify-center mt-5">
      {{ $t('product-finder.generate.title') }}<br>
      {{ $t('product-finder.generate.subtitle') }}
    </h1>
  </div>
</template>
<script>

import CREATE_COMPANY_ANAMNESIS from '@/modules/auth/Register/queries/createCompanyAnamnesis.gql'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import { getRegistrationNextStep, removeRegistrationNextStep } from '@/lib/registrationNextStep'

export default {
  data () {
    return {
      companyId: this.$auth?.user?.companyId ?? null,
      isActiveStep: getRegistrationNextStep() === 'ANAMNESIS'
    }
  },
  computed: {
    perfectProduct () {
      return JSON.parse(sessionStorage.getItem('perfectProduct'))
    }
  },
  async mounted () {
    // Delay the execution by 3 seconds in order loading image and text to be visible longer
    // Even when the request is sent(createCompany) the response is received in less than 1 second
    await new Promise(resolve => setTimeout(resolve, 3000))

    if (!this.companyId || this.isActiveStep) {
      await this.createCompany()
    }

    if (this.isActiveStep) {
      removeRegistrationNextStep()
      this.navigateToDashboard()
    } else {
      this.navigateToProductPackages()
    }
  },
  methods: {
    async createCompany () {
      try {
        const product = this.perfectProduct
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_COMPANY_ANAMNESIS,
          variables: {
            input: {
              businessDescription: product.businessDescription,
              country: product.country.countryCode,
              industry: product.industry.value,
              websiteUrl: product.websiteUrl.length ? formatHttpsUrl(product.websiteUrl) : ''
            }
          }
        })
        this.companyId = data.createCompanyAnamnesis.companyId
      } catch (error) {}
    },
    navigateToDashboard () {
      this.$router.push({ name: 'dashboard' })
    },
    navigateToProductPackages () {
      const product = this.perfectProduct
      this.$router.push({
        name: 'product-packages',
        query: {
          countryCode: product.country.countryCode,
          currencySymbol: product.country.currencySymbol,
          isYearly: true,
          companyId: this.companyId
        },
        replace: true
      })

      this.$emit('generated')
    }
  }
}

</script>
