<template>
  <div
    :style="cssProps"
    class="col additional-info pa-sm-0"
  >
    <h2 class="row justify-center additional-info-title text-center mx-auto col-sm-10 col-lg-6 px-sm-0">
      {{ $t('product-finder.additional-info.title') }}
    </h2>
    <div class="row industry-box pt-10 ma-0">
      <div class="col-sm-10 col-lg-6 mx-auto pa-0">
        <h4 class="text-start industry-box-title pb-5 ma-0">
          {{ $t('product-finder.additional-info.industry-box-title') }}
        </h4>
        <v-autocomplete
          v-model="industry"
          :items="industries"
          :search-input.sync="search"
          clearable
          item-text="text"
          item-value="value"
          outlined
          hide-details
          :placeholder="$t('product-finder.industry-placeholder')"
          class="ma-0 pointer"
          return-object
          @click:clear="removeIndustry"
        >
          <template v-slot:selection="data">
            <span>{{ data.item.text }}</span>
          </template>
          <template v-slot:item="data">
            <span class="ml-2">{{ data.item.text }}</span>
          </template>
          <template v-slot:no-data>
            <v-btn
              elevation="0"
              text
              outlined
              color="primary"
              class="ml-2"
              @click="industries.unshift({ value: search, text: search, key: search });
                      industry = { value: search, text: search, key: search };
                      search=''"
            >
              {{ $t('buttons.product-finder.add') }}: {{ search }}
            </v-btn>
          </template>
        </v-autocomplete>
      </div>
    </div>

    <div
      class="row pt-5 pb-8 ma-0"
    >
      <div class="col-sm-10 col-lg-6 mx-auto ma-0 pa-0">
        <h4 class="ma-0 pa-0 mb-5 text-start description-box-title">
          {{ $t('product-finder.additional-info.description-box-title') }}
        </h4>
        <v-textarea
          v-model="businessDescription"
          :placeholder="$t('product-finder.enter-description-placeholder')"
          rows="4"
          class="my-2"
          hide-details
          outlined
        />
      </div>
    </div>
    <div class="row ma-0">
      <div class="col-sm-10 col-lg-6 pa-0 mx-auto d-flex justify-space-between">
        <v-btn
          class="back col-6"
          elevation="0"
          x-large
          @click="back"
        >
          {{ $t('buttons.product-finder.back') }}
        </v-btn>
        <v-btn
          class="proceed col-6 ml-2"
          elevation="0"
          :style="buttonCss"
          x-large
          @click="proceed"
        >
          {{ $t('buttons.product-finder.finish') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { Industries } from '@/components/product-finder/productFinder'

export default {
  data () {
    return {
      industry: '',
      search: '',
      businessDescription: '',
      industries: []
    }
  },

  computed: {
    cssProps () {
      return {
        '--blue': this.$vuetify.theme.themes.light.markero.blue,
        '--dark-blue': this.$vuetify.theme.themes.light.markero.darkBlue
      }
    },
    hasQueries () {
      return Object.keys(this.$route.query).length > 0
    },
    perfectProduct () {
      return JSON.parse(sessionStorage.getItem('perfectProduct'))
    },
    buttonCss () {
      return {
        'background-color': this.$vuetify.theme.themes.light.markero.blue,
        color: 'white'
      }
    }
  },
  watch: {
    industry (val) {
      if (!this.perfectProduct) return
      const industry = { ...val }
      this.updateProperty('industry', industry)
      this.updateSessionStorageItem()
    },
    businessDescription (val) {
      if (!this.perfectProduct) return
      this.updateProperty('businessDescription', val)
      this.updateSessionStorageItem()
    }
  },
  mounted () {
    this.initializePropValues()
  },
  methods: {
    initializePropValues () {
      this.businessDescription = this.perfectProduct?.businessDescription ?? ''
      this.industries = this.getIndustries()
      this.industry = this.getDefaultIndustry()
      const industryExists = this.industries.some((industry) => industry.value === this.industry.value)
      if (!industryExists) {
        this.industries.unshift(this.industry)
      }
    },
    updateProperty (prop, val) {
      if (typeof this.perfectProduct === 'object') {
        this.perfectProduct[prop] = val
      }
    },
    removeIndustry () {
      this.perfectProduct.industry = {}
      this.updateSessionStorageItem()
    },
    getDefaultIndustry () {
      if (this.industries.length === 0) return ''

      return this.perfectProduct.industry ? this.perfectProduct.industry : this.industries
        .find(industry => industry.text?.length > 0)
    },
    getIndustries () {
      return Industries.map(industry => {
        if (industry.divider) return industry
        if (industry.header) { return { header: this.$t(industry.header) } }
        if (industry.value) { return { value: industry.value, text: this.$t(industry.text), key: industry.text } }
      })
    },
    updateSessionStorageItem () {
      sessionStorage.setItem('perfectProduct', JSON.stringify(this.perfectProduct))
    },
    proceed () {
      this.$emit('next')
    },
    back () {
      this.$emit('back')
    }
  }
}

</script>

<style scoped>
/* General Font Styles */
.additional-info, .additional-info-title, .description-box-title, .industry-box-title, .proceed, .back {
  font-family: 'Poppins', sans-serif;
}

/* Title Styles */
.additional-info-title, .description-box-title {
  font-style: normal;
}

.additional-info-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.description-box-title, .industry-box-title {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

/* Pointer Class */
.pointer {
  cursor: pointer;
}

/* Back Button Styles */
.back {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  border: 1px solid var(--blue) !important;
  padding: 13px 34px !important;
  background-color: white !important;
  border-radius: 10px;
  color: var(--blue);
  height: fit-content !important;
  text-transform: none;
}

/* Proceed Button Styles */
.proceed {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  color: white;
  height: fit-content !important;
  padding: 14px 34px !important;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(47, 22, 232, 0.25) !important;
  text-transform: none;
}

/* Vuetify Specific Overrides */
::v-deep fieldset {
  background-color: white;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin: 12px 0;
}

::v-deep .v-text-field--outlined .v-label {
  top: 15px;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px;
}

::v-deep .v-text-field input {
  padding: 10px 0 8px;
  font-family: 'Poppins', sans-serif;
}

::v-deep .theme--light.v-btn:hover::before {
  background-color: var(--dark-blue);
}
</style>
