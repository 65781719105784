<template>
  <div>
    <BasicInfo
      v-if="activeStep === 'basic-info'"
      @next="activeStep = 'additional-info'"
    />
    <AdditionalInfo
      v-if="activeStep === 'additional-info'"
      @next="activeStep = 'generate'"
      @back="activeStep = 'basic-info'"
    />
    <Generate
      v-if="activeStep === 'generate'"
      @generated="onGenerated"
    />
  </div>
</template>

<script>
import BasicInfo from '@/components/product-finder/BasicInfo.vue'
import AdditionalInfo from '@/components/product-finder/AdditionalInfo.vue'
import Generate from '@/components/product-finder/Generate.vue'
import brandingMixin from '@/mixins/branding'
export default {
  components: { Generate, AdditionalInfo, BasicInfo },
  mixins: [brandingMixin],
  props: {
    activeStep: {
      type: String,
      default: 'basic-info'
    }
  },
  watch: {
    activeStep (val) {
      this.$emit('updateActiveStep', val)
    }
  },
  methods: {
    onGenerated () {
      this.$emit('updateActiveStep', 'basic-info')
    }
  }
}
</script>
